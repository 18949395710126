import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { logout } from "../../redux/auth_slice/login_user_slice";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import config from "../../../config";

export const AppTopbar = (props) => {
  const loginUser = useSelector((state) => state.loginUser);
  const { user } = loginUser;

  const history = useHistory();
  const dispatch = useDispatch();

  const signoutConfirm = () => {
    confirmDialog({
      message: "Are you sure you want to Logout?",
      icon: "pi pi-exclamation-triangle",
      header: "Confirmation",
      acceptClassName: "p-button p-button p-button-sm width-80",
      accept: () => {
        dispatch(logout());
        localStorage.clear();
        sessionStorage.clear();
        history.push("/login");
      },
      reject: () => {},
    });
  };

  const imageUrl = `${config.baseUrl}/${user?.data?.profile}`;

  return (
    <>
      <ConfirmDialog />
      <div className="layout-topbar">
        <div className="layout-topbar-ellipsis">
          <button
            type="button"
            className="p-link  layout-menu-button layout-topbar-button"
            onClick={props.onToggleMenuClick}
          >
            <i className="pi pi-bars" />
          </button>
          {props.currentRouteLabel && (
            <div className="layout-topbar-heading">
              {props.currentRouteLabel}
            </div>
          )}
        </div>

        <div className="layout-topbar-user">
          {imageUrl && <img src={imageUrl} alt="Profile" />}
          {user?.data?.name && (
            <h6
              style={{
                textTransform: "capitalize",
                fontWeight: "700",
                color: "#7c251f",
              }}
            >
              Hello, &nbsp; {user?.data?.name} &nbsp;{" "}
            </h6>
          )}
          <button
            className="p-link layout-topbar-button"
            onClick={() => {
              signoutConfirm();
            }}
          >
            <i className="pi pi-power-off" />
          </button>
        </div>
      </div>
    </>
  );
};
